import muiRootWrapper from "./mui-root-wrapper"
import browserHydrateFunction from './browser-hydrate-function';

export const wrapRootElement = muiRootWrapper

const scriptForGTM = `window.dataLayer = window.dataLayer || []; window.dataLayer.push({ "platform": "gatsby" }); (function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + ''; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-MWDLKHN');`;

export const shouldUpdateScroll = () => {
  return [0, 0]
}

export const onInitialClientRender = () => {
  document.getElementsByClassName("prelauder")[0].style.display = "none"
  setTimeout(() => {
    if (process.env.NODE_ENV === 'production') {
      const gtmScript = document.createElement('script');
      gtmScript.defer = true;
      gtmScript.textContent = scriptForGTM;

      document.body.appendChild(gtmScript);
    }
  }, 4000);
}

// export const onRouteUpdate = () => {
//   // const preloader = document.getElementsByClassName("prelauder");

//   // setTimeout(() => {
//   //   // preloader[0].style.display = "none";
//   // }, 100);
// };

// export const onClientEntry = () => {
//   // console.log('onClientEntry')
//   // document.getElementsByClassName("prelauder")[0].style.display = "flex"
// }


export const replaceHydrateFunction = browserHydrateFunction;
